import { TimeSnackbarShow } from './../../config';
import { UserService } from 'src/app/services/user/user.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

declare let $: any;

@Component({
  selector: 'app-modal-rejection-variant',
  templateUrl: './modal-rejection-variant.component.html',
  styleUrls: ['./modal-rejection-variant.component.scss'],
  providers:[
    MatSnackBar
  ]
})
export class ModalRejectionVariantComponent implements OnInit, OnDestroy {
  @Input() id: number;
  private destroyed$ = new Subject();

  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  closeModal() {
    $('#rejectionVariantModal').modal('hide');
  }

  onCancel() {
    this.userService.cancelRequest(this.id).subscribe(
      (res: any) => {
        this.snackBar.open('Успешный отказ', null, {
          duration: TimeSnackbarShow,
          verticalPosition: 'top'
        });
        $('#sendOtpModal').modal('hide');
        $('#rejectionVariantModal').modal('hide');
      },
      (err) => {
        if (err.status === 401) {
          this.snackBar.open('Ошибка сервера', null, {
            duration: TimeSnackbarShow,
            verticalPosition: 'top'
          });
        }
        if (err.status === 400 || err.status === 404) {
          this.snackBar.open(err.error.detail, null, {
            duration: TimeSnackbarShow,
            verticalPosition: 'top'
          });
        }
      }
    );
  }
}