import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'numseparator'})
export class NumSeparatorPipe implements PipeTransform {
    public transform(value): any {
        if (value !== undefined && value !== null) {
            value = Number(value) || 0;
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        } else {
            return '';
        }
    }
}
