import { Component } from "@angular/core";
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  ActivatedRoute,
} from "@angular/router";
import { filter, take } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams && queryParams.utm_source) {
        localStorage.setItem("utmSource", queryParams.utm_source);
      }
      queryParams && localStorage.setItem("utms", JSON.stringify(queryParams));
    });
    router.events
      .pipe(
        filter(
          (e) =>
            e instanceof NavigationStart ||
            e instanceof NavigationEnd ||
            e instanceof NavigationCancel ||
            e instanceof NavigationError
        )
      )
      .subscribe(async (e) => {
        if (e instanceof NavigationEnd) {
          document.documentElement.scrollTop = 0;
        }
      });
  }
}
