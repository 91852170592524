import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimeSnackbarShow } from 'src/app/config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WooppayService } from 'src/app/services/wooppay/wooppay.service';
import { AuthService } from 'src/app/services/auth/auth.service';

declare let $, window: any;

@Component({
  selector: 'app-modal-wooppay',
  templateUrl: './modal-wooppay.component.html',
  styleUrls: ['./modal-wooppay.component.scss'],
  providers:[
    MatSnackBar
  ]
})
export class ModalWooppayComponent implements OnInit, OnDestroy {
  public checkToValid = false;
  public sum: number;
  public requestErrors: any = {};

  private destroyed$ = new Subject();

  constructor(
    private snackBar: MatSnackBar,
    private wooppayService: WooppayService,
    private authService: AuthService
  ) { }

  ngOnInit() {
  }

  public pay(amount: number) {
    if (amount > 252000) {
      this.sum = 252000;

      return this.snackBar.open('Возможная сумма для пополнения через Woppay не более 252 000 тенге.', null, {
        duration: TimeSnackbarShow,
        verticalPosition: 'top'
      });
    }

    const openSnackbar = this.snackBar.open('Необходимо совершить платеж до 17.00, при несвоевременной оплате вы выйдите на просрочку.', 'понятно', {
      duration: TimeSnackbarShow,
      verticalPosition: 'top'
    });

    openSnackbar.afterDismissed().subscribe(
      () => {
        this.wooppayService.pay(amount).pipe(
          takeUntil(this.destroyed$)
        ).subscribe(
          (res) => {
            console.log(res);
            window.location.href = res.operationUrl;
          },
          (err) => {
            console.log(err);
            this.requestErrors = err.error;
            let errMsg = '';
            for (let key in err.error) {
              errMsg += err.error[key] + '\n';
            }
    
            this.snackBar.open(errMsg || 'Что то пошло не так.', null, {
              duration: TimeSnackbarShow,
              verticalPosition: 'top'
            });
    
            if (err.status === 401) {
              $('#wooppayModal').modal('hide');
              this.snackBar.open('Авторизуйтесь пожалуйста.', null, {
                duration: TimeSnackbarShow,
                verticalPosition: 'top'
              });
    
              return this.authService.logout();
            }
          }
        );
      },
      (err) => {
        console.log(err);
      }
    );

  } 

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

}
