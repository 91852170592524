import { Component, OnInit, OnDestroy } from '@angular/core';
import { FaqService } from 'src/app/services/faq/faq.service';
import { UnsubscribeService } from 'src/app/services/unsubscribe/unsubscribe.service';
import { IFaq } from 'src/app/models/faq';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, OnDestroy {
  private subscribes = [];
  public faqList: Array<IFaq> = [];

  constructor(
    private faqService: FaqService,
    private unsubscribeService: UnsubscribeService
  ) { }

  ngOnInit() {
    this.getFaqList();
  }

  private getFaqList() {
    this.subscribes.push(
      this.faqService.getFaqList().subscribe(
        (res) => {
          this.faqList = res;
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }

  ngOnDestroy() {
    this.unsubscribeService.unsubscribings(this.subscribes);
  }

}
