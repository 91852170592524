import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import {CalculatorProperty} from "../../models/calculator";
import {Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(
    private http: HttpClient
  ) { }

  getDocuments() {
    return this.http.get<any>(`${environment.apiUrl}/api/content/document/`);
  }

  getCalculatorProperties(): Observable<Array<CalculatorProperty>> {
    return this.http.get<Array<CalculatorProperty>>(
      `${environment.apiUrl}/api/content/calculator/`
    )
  }
}
