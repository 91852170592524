// cores
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// modules
import { AppRoutingModule, routedComponents } from './app-routing.module';
import { SharedModule } from './modules/shared.module';

// guards
import { MainGuard } from './resolvers/main.guard';
import { LocalStorageModule, LocalStorageService } from 'angular-2-local-storage';

// modules
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

// components
import { AppComponent } from './app.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AuthInterceptorModule } from './modules/auth-interceptor/auth-interceptor.module';

@NgModule({
  declarations: [
    AppComponent,
    routedComponents
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthInterceptorModule.forRoot(),
    SharedModule,
    LocalStorageModule.forRoot({
      prefix: 'app-root',
      storageType: 'localStorage'
    }),
    MatSliderModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    MainGuard,
    LocalStorageService,
    {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
