import { TimeSnackbarShow } from './../../config';
import { UserService } from 'src/app/services/user/user.service';
import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { EventEmitter } from '@angular/core';

declare let $: any;

@Component({
  selector: 'app-modal-otp',
  templateUrl: './modal-otp.component.html',
  styleUrls: ['./modal-otp.component.scss'],
  providers:[
    MatSnackBar
  ]
})
export class ModalOTPComponent implements OnInit, OnDestroy {
  @Input() id: number;
  @Input() printApplication: string;
  @Input() printCreditContract: string;
  @Input() printCreditContractSchedule: string;
  // @Output() text = new EventEmitter();
  private destroyed$ = new Subject();

  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar,
  ) { }
  
  otp_code: number = null
  disabled: boolean = false
  disabledBtn: boolean = true
  replay: number = 3
  countdown: number = 60
  text = ''
  check1 = false
  check2 = false

  ngOnInit() {

  }
  
  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  numericOnly(event): boolean {    
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  resendOTP() {
    this.disabled = true
   
    let timer = setInterval(() => {
      if(this.countdown > 0) {
        this.countdown = --this.countdown
      } else { 
        clearInterval(timer) 
        this.disabled = false
        this.countdown = 60
      }
    }, 1000)
    

    this.userService.sendOTP(this.id).subscribe(
      (res: any) => {
        this.snackBar.open('OTP код отправлен на ваш номер', null, {
          duration: TimeSnackbarShow,
          verticalPosition: 'top'
        });
        this.replay -= 1
        if (this.replay === 0) {this.disabled = true}
      },
      (err) => {
        if (err.status === 400) {
          this.snackBar.open(err.error.detail, null, {
            duration: TimeSnackbarShow,
            verticalPosition: 'top'
          });
        }
      }
    );
  }

  onScoringChange(state: boolean, check: string) {
    if (check == "check1") {
      this.check1 = state
    } else {
      this.check2 = state
    }
  }

  verifyOTP() {
    this.userService.singOTP(this.id, this.otp_code).subscribe(
      (res: any) => {
        if (res.status) {
          this.text = 'Поздравляем Вас с успешным оформлением микрокредита!';
          $('#defaultModal').modal('show');
        } else {
          this.text = 'Введенный код неверный, нажмите на Отправить СМС и введите код повторно';
          $('#defaultModal').modal('show');
        }
      },
      (err) => {
        if (err.status === 401) {
          this.text = 'Ошибка сервера';
          $('#defaultModal').modal('show');
        }
        if (err.status === 400) {
          this.text = err.error.detail;
          $('#defaultModal').modal('show');
        }
      }
    );
  }

  choiceVariant() {
    $('#variantModal').modal('show');
  }

  rejectionVariant() {
    $('#rejectionVariantModal').modal('show');
  }
}