// cores
import { Component, OnInit, AfterContentInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Address, TimeSnackbarShow } from 'src/app/config';
import { Letter } from 'src/app/models/letter';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RequestService } from 'src/app/services/request/request.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

declare var ymaps;
@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit, AfterContentInit, OnDestroy {
  private address = Address;
  public newLetter = new Letter();
  public checkToValid = false;
  public requestErrors: any = {};
  private destroyed$ = new Subject();

  constructor(
    private snackBar: MatSnackBar,
    private requestService: RequestService
  ) { }

  ngOnInit() {
  }

  ngAfterContentInit() {
    if (!environment.isServer) {
      if (!ymaps) {
        setTimeout(() => {
          ymaps.ready().then(() => {
            this.initMap();
          });
        }, 1000);
        return;
      }

      ymaps.ready().then(() => {
        this.initMap();
      });
    }
  }

  private initMap() {
    let myPlacemark,
      myMap = new ymaps.Map(
        'mapShow',
        {
          center: [this.address.latitude, this.address.longitude],
          zoom: 17,
        }
    );

    myPlacemark = createPlacemark([Address.latitude, Address.longitude]);
    myMap.geoObjects.add(myPlacemark);

    function createPlacemark(coords) {
      return new ymaps.Placemark(
        coords,
        { hintContent: 'г. Алматы, пр. Аль-Фараби, дом 77/1, 10-й этаж, офис 56' },
        {
          draggable: false,
        }
      );
    }
  }

  public checkInputVal(phone: any) {
    if (this.requestErrors && this.requestErrors.phone) {
      this.requestErrors.phone = null;
    }

    if (phone.value[4] == 8 || phone.value[0] == 8) {
      phone.value = '+7 (';
      this.snackBar.open('Пишите в формате +7 (700) 000-00-00', null, {
        duration: TimeSnackbarShow,
        verticalPosition: 'top'
      });
    }
  }

  public checkFields(letter: Letter): Boolean {
    const fieldsError = Object.keys(this.requestErrors);
    for(let i=0; i<fieldsError.length; i++) {
      if (this.requestErrors[fieldsError[i]]) {
        return false;
      }
    }

    const fields = Object.keys(letter);
    for(let i=0; i<fields.length; i++) {
      if (!letter[fields[i]]) {
        return false;
      }
    }

    if (!letter.phone || letter.phone.length < 18) {
      return false;
    }

    return true;
  }

  public sendLetter(letter: Letter) {
    console.log('sendLetter', letter);
    this.requestService.writeMe(letter).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      (res) => {
        this.snackBar.open('Успешно отправлено!', null, {
          panelClass: 'success-snackbar',
          duration: TimeSnackbarShow,
          verticalPosition: 'top'
        });
        this.newLetter = new Letter();
      },
      (err) => {
        this.requestErrors = err.error;
        let errMsg = '';
        if (err.error && err.error.errors) {
          for (let i=0; i<err.error.errors.length; i++) {
            errMsg += err.error.errors[i].message + '\n';
          }
        } else {
          for (let key in err.error) {
            errMsg += err.error[key] + '\n';
          }
        }
        this.snackBar.open(errMsg || 'Ошибка при отправке!', null, {
          duration: TimeSnackbarShow,
          verticalPosition: 'top'
        });
        console.log(err);
      }
    )
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

}
