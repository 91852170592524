import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TimeSnackbarShow } from "src/app/config";
import { TPaymentContracts } from "src/app/models/user";
import { WooppayService } from "src/app/services/wooppay/wooppay.service";

@Component({
  selector: "app-smartbilling",
  templateUrl: "./smartbilling.component.html",
  styleUrls: ["./smartbilling.component.scss"],
})
export class SmartbillingComponent implements OnInit {
  public checkToValid = false;
  public loading = false;
  public requestErrors: any = {};
  private destroyed$ = new Subject();
  public iin: string = "";
  public amount: number;
  public current: number = 1;
  public contract_number: string = "";
  public contract_date: string = "";
  public contracts: TPaymentContracts;

  constructor(
    private snackBar: MatSnackBar,
    private wooppayService: WooppayService
  ) {}

  ngOnInit() {}

  onChangeAmount() {
    if (this.contracts.contracts.length) {
      this.amount =
        this.current == 1
          ? this.contracts.contracts.find(
              (item) => item.contract_number == this.contract_number
            ).current_debt
          : this.current == 2
          ? this.contracts.contracts.find(
              (item) => item.contract_number == this.contract_number
            ).minimum_amount_of_partial_repayment
          : this.contracts.contracts.find(
              (item) => item.contract_number == this.contract_number
            ).payment_amount;
    }
  }

  onSelect(contract: string) {
    this.contract_number = contract;
    this.contract_date = this.contracts.contracts.find(
      (item) => item.contract_number == contract
    ).contract_date;
    this.onChangeAmount();
  }

  onChangeCurrent(num: number) {
    this.current = num;
    this.amount = null;
    this.contract_date = "";
    this.onSelect("");
  }

  public onCheckContract() {
    this.loading = true;
    this.wooppayService
      .checkContract(this.iin)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res: any) => {
          this.contracts = res;
        },
        (err) => {
          this.requestErrors = err.error;
          let errMsg = "";
          for (let key in err.error) {
            errMsg += err.error[key] + "\n";
          }

          this.snackBar.open(errMsg || "Что то пошло не так!", null, {
            duration: TimeSnackbarShow,
            verticalPosition: "top",
          });
        }
      )
      .add(() => (this.loading = false));
  }

  public onSendContract() {
    this.loading = true;
    this.wooppayService
      .contract(this.iin, this.amount, this.contract_number, this.contract_date)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res: any) => {
          window.location.href = res.pay_link;
        },
        (err) => {
          this.requestErrors = err.error;
          let errMsg = "";
          for (let key in err.error) {
            errMsg += err.error[key] + "\n";
          }

          this.snackBar.open(errMsg || "Что то пошло не так!", null, {
            duration: TimeSnackbarShow,
            verticalPosition: "top",
          });
        }
      )
      .add(() => (this.loading = false));
  }
}
