import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

import { UserService } from 'src/app/services/user/user.service';

declare let $: any;

@Component({
  selector: 'app-modal-card',
  templateUrl: './modal-card.component.html',
  styleUrls: ['./modal-card.component.scss'],
  providers:[
    MatSnackBar
  ]
})
export class ModalCardComponent implements OnInit, OnDestroy {
  @Input() contract: any = {};

  public cards: any = [];
  public checkToValid = false;
  public requestErrors: any = {};

  private destroyed$ = new Subject();

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getCardList();
  }

  ngOnChanges() {
    if (this.contract.contract_number) {
      this.getCardList()
    }
  }

  public getCardList() {
    this.userService.getCardList(
      this.contract.contract_number
    ).subscribe(_ => {
      this.cards = _
      console.log(_)
    })
  }

  public makePayment() {

  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

}
