import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subject } from "rxjs";
import { TimeSnackbarShow } from "src/app/config";
import { RequestService } from "src/app/services/request/request.service";
import { takeUntil } from "rxjs/operators";

declare let $: any;

@Component({
  selector: "app-modal-otp-confirm",
  templateUrl: "./modal-otp-confirm.component.html",
  styleUrls: ["./modal-otp-confirm.component.scss"],
  providers: [MatSnackBar],
})
export class ModalOTPConfirmComponent implements OnInit, OnDestroy {
  @Input() uuid: string;
  private destroyed$ = new Subject();

  constructor(
    private requestService: RequestService,
    private snackBar: MatSnackBar
  ) {}

  public checkToValid = false;
  public requestErrors: any = {};
  otp_code = "";
  text = "";

  ngOnInit() {}

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  public verifyOTP() {
    this.checkToValid = true;

    this.requestService
      .scoringStart(this.otp_code, this.uuid)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          $("#sendOtpConfirmModal").modal("hide");

          $("#successModal").modal("show");
          setTimeout(() => {
            $("#successModal").modal("hide");
          }, 3000);
        },
        (err: any) => {
          this.requestErrors = err.error;
          let errMsg = "";
          if (err.error && err.error.errors) {
            for (let i = 0; i < err.error.errors.length; i++) {
              errMsg += err.error.errors[i].message + "\n";
            }
          } else {
            for (let key in err.error) {
              errMsg += err.error[key] + "\n";
            }
          }

          this.snackBar.open(errMsg || "Ошибка при отправке!", null, {
            duration: TimeSnackbarShow,
            verticalPosition: "top",
          });
          this.otp_code = "";
        }
      );
  }
}
