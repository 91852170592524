import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MyLocalStorageService } from "../local-storage/local-storage.service";
import { Router } from "@angular/router";
import { RestorePassword } from "src/app/models/restore";

import {
  Idle,
  StorageInterruptSource,
  DocumentInterruptSource,
} from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { Registration2 } from "src/app/models/registration";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;
  title = "angular-idle-timeout";
  private loggedIn: BehaviorSubject<boolean>;

  constructor(
    private http: HttpClient,
    private localStorageService: MyLocalStorageService,
    private router: Router,
    private idle: Idle,
    private keepalive: Keepalive
  ) {
    let token = this.localStorageService.getToken();
    let loggedIn = token !== undefined && token !== null;
    this.loggedIn = new BehaviorSubject<boolean>(loggedIn);

    idle.setIdle(1);
    idle.setTimeout(899);
    let interruptions = [
      new DocumentInterruptSource(
        "keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll"
      ),
      new StorageInterruptSource(),
    ];
    idle.setInterrupts(interruptions);
    let lastIdleTimeRaw = this.localStorageService.getIdleTime();
    if (loggedIn) {
      let lastIdleTime = new Date();
      if (lastIdleTimeRaw) {
        lastIdleTime = new Date(lastIdleTimeRaw);
      }
      let current = new Date();
      let secondsPass = (current.getTime() - lastIdleTime.getTime()) / 1000;

      if (secondsPass > 899) {
        this.timedOut = true;
        this.logout();
        return;
      }
    }

    idle.onIdleEnd.subscribe(() => {
      this.idleState = "No longer idle.";
    });

    idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      this.logout();
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      let current = new Date();
      this.localStorageService.setIdleTime(current);
    });

    keepalive.interval(15);

    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.isLoggedIn().subscribe((userLoggedIn: boolean) => {
      if (userLoggedIn) {
        idle.watch();
        this.timedOut = false;
      } else {
        idle.stop();
      }
    });
  }

  isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  setLoggedIn(value: boolean) {
    this.loggedIn.next(value);
  }

  doRegistration(body: { phone: string; iin: string }) {
    return this.http.post<any>(
      `${environment.apiUrl}/api/user/auth/register/`,
      body
    );
  }

  getInfo(token: string) {
    var header = {
      headers: new HttpHeaders().set("Authorization", `Token ${token}`),
    };

    return this.http.get<any>(
      `${environment.apiUrl}/api/user/auth/profile/`,
      header
    );
  }

  doRegistration2(
    body: {
      profile_id: string;
      first_name: string;
      last_name: string;
      middle_name: string;
      document_type: string;
      document_number: string;
      document_exp_date: string;
      bank_account_number: string;
    },
    token: string
  ) {
    var header = {
      headers: new HttpHeaders().set("Authorization", `Token ${token}`),
    };
    return this.http.post<any>(
      `${environment.apiUrl}/api/user/auth/profile/`,
      body,
      header
    );
  }

  doRegistration3(
    body: { password: string; confirmPassword: string },
    token: string
  ) {
    var header = {
      headers: new HttpHeaders().set("Authorization", `Token ${token}`),
    };
    return this.http.post<any>(
      `${environment.apiUrl}/api/user/auth/set-password/`,
      body,
      header
    );
  }

  doLogin(body: { phone: string; password: string }) {
    return this.http.post<any>(
      `${environment.apiUrl}/api/user/auth/login/`,
      body
    );
  }

  logout() {
    this.setLoggedIn(false);
    this.localStorageService.clearStorage();
    this.router.navigate(["/auth"]);
  }

  public sendResetPasswordSms(phone: string) {
    return this.http.post<any>(
      `${environment.apiUrl}/api/user/auth/send-otp/`,
      {
        phone: phone,
      }
    );
  }

  public restorePassword(model: RestorePassword) {
    return this.http.post<any>(
      `${environment.apiUrl}/api/user/auth/reset-password/`,
      model
    );
  }

  public sendSms(phone: string) {
    return this.http.post<any>(`${environment.apiUrl}/api/sms/send/`, {
      phone: phone,
    });
  }

  public checkSmsCode(phone: string, code: number) {
    // depracated
    return this.http.post<any>(`${environment.apiUrl}/api/sms/check/`, {
      phone: phone,
      code: code,
    });
  }

  public confirmSmsCode(phone: string, code: number) {
    return this.http.post<any>(
      `${environment.apiUrl}/api/user/auth/register_confirm/`,
      {
        phone: phone,
        code: code,
      }
    );
  }

  public resendSms(phone: string) {
    return this.http.post<any>(
      `${environment.apiUrl}/api/user/auth/register_resend_code/`,
      {
        phone: phone,
      }
    );
  }

  public sendEgovCode(token) {
    var header = {
      headers: new HttpHeaders().set("Authorization", `Token ${token}`),
    };
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/auth/send-egov_code/`,
      header
    );
  }
}
