

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { MyLocalStorageService } from '../services/local-storage/local-storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private localStorage: MyLocalStorageService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const token: string = this.localStorage.getToken();
        if (token) {
            let request = req.clone({
                setHeaders: {
                    'Authorization': 'Token ' + token,
                    // 'content-type': 'application/json'
                }
            });
            return next.handle(request);
        }
        return next.handle(req);
    }
}
