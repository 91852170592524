import { TimeSnackbarShow } from "../../config";
import { UserService } from "src/app/services/user/user.service";
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subject } from "rxjs";

declare let $: any;

@Component({
  selector: "app-modal-signing-contract",
  templateUrl: "./modal-signing-contract.component.html",
  styleUrls: ["./modal-signing-contract.component.scss"],
  providers: [MatSnackBar],
})
export class ModalSigningContractComponent implements OnInit, OnDestroy {
  @Input() id: number;
  @Input() printApplication: string;
  @Input() printCreditContract: string;
  @Input() printCreditContractSchedule: string;
  private destroyed$ = new Subject();

  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar
  ) {}

  cardFront: File = null;
  cardBack: File = null;
  photo: File = null;
  disabled: boolean = true;
  text = "";
  check1 = false;
  check2 = false;
  counter = 10;

  ngOnInit() {}

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  public handleFileCardFront(files: FileList) {
    this.cardFront = files.item(0);
    this.disabled =
      this.cardFront &&
      this.photo &&
      this.cardBack &&
      this.check1 &&
      this.check2
        ? false
        : true;

    var reader: FileReader = new FileReader();
    reader.onload = function (e) {
      $("#cardFront").attr("src", reader.result);
    }.bind(this);

    reader.readAsDataURL(files.item(0));
  }

  public handleFileCardBack(files: FileList) {
    this.cardBack = files.item(0);
    this.disabled =
      this.cardFront &&
      this.photo &&
      this.cardBack &&
      this.check1 &&
      this.check2
        ? false
        : true;

    var reader: FileReader = new FileReader();
    reader.onload = function (e) {
      $("#cardBack").attr("src", reader.result);
    }.bind(this);

    reader.readAsDataURL(files.item(0));
  }

  public handleFilePhoto(files: FileList) {
    this.photo = files.item(0);
    this.disabled =
      this.cardFront &&
      this.photo &&
      this.cardBack &&
      this.check1 &&
      this.check2
        ? false
        : true;

    var reader: FileReader = new FileReader();
    reader.onload = function (e) {
      $("#photo").attr("src", reader.result);
    }.bind(this);

    reader.readAsDataURL(files.item(0));
  }

  public sendFiles() {
    this.userService
      .uploadFile(this.id, this.cardFront, this.cardBack, this.photo)
      .subscribe(
        (res: any) => {
          if (res.status === "success") {
            this.openOTP();
          } else if (res.status === "failure") {
            this.text =
              "Качество фото не соответствуют требованиям. Пожалуйста, загрузите новые фото.";
            $("#defaultModal").modal("show");
          } else {
            $("#signingContractModal").modal("hide");
            this.text =
              "Электронное подписание прошло неуспешно. Пожалуйста, обратитесь к менеджеру.";
            $("#defaultModal").modal("show");
          }
          this.counter = 10 - res.match_count;
        },
        (err) => {
          if (err.status === 401) {
            this.snackBar.open("Ошибка сервера", null, {
              duration: TimeSnackbarShow,
              verticalPosition: "top",
            });
          }
          if (err.status === 400) {
            this.snackBar.open(err.error.detail, null, {
              duration: TimeSnackbarShow,
              verticalPosition: "top",
            });
          }
        }
      );
  }

  openOTP() {
    $("#sendOtpModal").modal("show");
    $("#signingContractModal").modal("hide");
    this.userService.sendOTP(this.id).subscribe(
      (res: any) => {
        $("#sendOtpModal").modal("show");
        $("#signingContractModal").modal("hide");
      },
      (err) => {
        $("#signingContractModal").modal("hide");
        if (err.status === 400) {
          this.snackBar.open(err.error.detail, null, {
            duration: TimeSnackbarShow,
            verticalPosition: "top",
          });
        }
      }
    );
  }

  onScoringChange() {
    this.disabled =
      this.cardFront &&
      this.photo &&
      this.cardBack &&
      this.check1 &&
      this.check2
        ? false
        : true;
  }
}
