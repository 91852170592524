import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class WooppayService {
  constructor(private http: HttpClient) {}

  public pay(amount: number) {
    return this.http.post<{ operationUrl: string }>(
      `${environment.apiUrl}/api/user/loan/payment_wooppay`,
      {
        amount: amount,
        back_url: environment.wooppay.backUrl,
      }
    );
  }

  public checkContract(iin: string) {
    return this.http.get<{ operationUrl: string }>(
      `${environment.apiUrl}/api/user/loan/payment/${iin}/contract/`
    );
  }

  public contract(
    iin: string,
    amount: number,
    contract_number: string,
    contract_date: string
  ) {
    return this.http.post<{ operationUrl: string }>(
      `${environment.apiUrl}/api/user/loan/payment/${iin}/contract/`,
      { amount, contract_number, contract_date }
    );
  }
}
