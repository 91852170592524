import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserService } from "src/app/services/user/user.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { TimeSnackbarShow } from "src/app/config";

declare let $: any;

@Component({
  selector: "app-modal-sign",
  templateUrl: "./modal-sign.component.html",
  styleUrls: ["./modal-sign.component.scss"],
  providers: [MatSnackBar],
})
export class ModalSignComponent implements OnInit, OnDestroy {
  @Output() editEvent = new EventEmitter<string>();
  @Input() phone: string;

  disabled: boolean = true;
  otp_code: number = null;
  replay: number = 3;
  countdown: number = 60;
  text: string = "";

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private snackBar: MatSnackBar
  ) {}

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  ngOnInit() {
    let timer = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown = --this.countdown;
      } else {
        clearInterval(timer);
        this.disabled = false;
        this.countdown = 60;
      }
    }, 1000);
  }

  resendOTP() {
    this.disabled = true;

    let timer = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown = --this.countdown;
      } else {
        clearInterval(timer);
        this.disabled = false;
        this.countdown = 60;
      }
    }, 1000);

    this.userService.getValidateToSign().subscribe(
      (res: any) => {
        this.snackBar.open("OTP код отправлен на ваш номер", null, {
          duration: TimeSnackbarShow,
          verticalPosition: "top",
        });
        this.replay -= 1;
        if (this.replay === 0) {
          this.disabled = true;
        }
      },
      (err) => {
        if (err.status === 400) {
          this.snackBar.open(err.error.detail, null, {
            duration: TimeSnackbarShow,
            verticalPosition: "top",
          });
        }
      }
    );
  }

  verifyOTP() {
    this.userService.postValidateToSign(this.otp_code).subscribe(
      (res: any) => {
        if (res.status) {
          this.snackBar.open(
            "Поздравляем Вас с успешным оформлением микрокредита!",
            null,
            {
              duration: TimeSnackbarShow,
              verticalPosition: "top",
            }
          );
          this.closeModal();
          this.editEvent.emit();
        } else {
          this.snackBar.open(
            "Введенный код неверный, нажмите на Отправить СМС и введите код повторно",
            null,
            {
              duration: TimeSnackbarShow,
              verticalPosition: "top",
            }
          );
        }
      },
      (err) => {
        if (err.status === 401) {
          this.snackBar.open("Ошибка сервера", null, {
            duration: TimeSnackbarShow,
            verticalPosition: "top",
          });
        }
        if (err.status === 400) {
          this.snackBar.open(err.error.detail || err.error.otp[0], null, {
            duration: TimeSnackbarShow,
            verticalPosition: "top",
          });
        }
      }
    );
  }

  ngOnDestroy() {}

  closeModal() {
    $(".modal").modal("hide");
  }
}
