import { TimeSnackbarShow } from './../../config';
import { UserService } from 'src/app/services/user/user.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

declare let $: any;

@Component({
  selector: 'app-modal-default',
  templateUrl: './modal-default.component.html',
  styleUrls: ['./modal-default.component.scss'],
  providers:[
    MatSnackBar
  ]
})
export class ModalDefaultComponent implements OnInit, OnDestroy {
  @Input() text: string;
  private destroyed$ = new Subject();

  disabled: boolean = true

  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  closeModal() {
    $('#sendOtpModal').modal('hide');
    $('#defaultModal').modal('hide');
  }
}