import { environment } from '../environments/environment';
const ApiUrl = environment.apiUrl;

export const Methods = {
    post: 'POST',
    put: 'PUT',
    delete: 'DELETE',
    get: 'GET'
}

export const Address = {
    latitude: 43.219749,
    longitude: 76.928756,
    text: 'г. Алматы, пр. Аль-Фараби, дом 77/1, 10-й этаж,офис 56'
}

export const TimeSnackbarShow = 5000;
