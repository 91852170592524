// core
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { MyLocalStorageService } from '../services/local-storage/local-storage.service';

@Injectable()
export class MainGuard implements CanActivate {

    constructor(
        private router: Router,
        private localStorage: MyLocalStorageService
    ) {
    }

    canActivate() {
        const token = this.localStorage.getToken();
        if (token) {
            return true;
        }
        this.router.navigate(['/auth']);
        return false;
    }
}
