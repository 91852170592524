import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal-payments',
  templateUrl: './modal-payments.component.html',
  styleUrls: ['./modal-payments.component.scss'],
  providers:[
    MatSnackBar
  ]
})
export class ModalPaymentsComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject();

  constructor(
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

}
