import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AuthInterceptor } from 'src/app/interceptors/auth.interceptor';

@NgModule({
    imports: [CommonModule],
    providers: [AuthService]
})
export class AuthInterceptorModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AuthInterceptorModule,
            providers: [
              {
                provide: HTTP_INTERCEPTORS,
                useClass: AuthInterceptor,
                multi: true
              }
            ]
        };
    }
}
