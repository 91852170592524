import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TimeSnackbarShow } from "src/app/config";
import { Subject } from "rxjs";
import { RequestService } from "src/app/services/request/request.service";
import { takeUntil } from "rxjs/operators";
import { ScoringApply } from "src/app/models/apply";

declare let $: any;

@Component({
  selector: "app-modal-application",
  templateUrl: "./modal-application.component.html",
  styleUrls: ["./modal-application.component.scss"],
  providers: [MatSnackBar],
})
export class ModalApplicationComponent implements OnInit, OnDestroy {
  @Input() month: number;
  @Input() amount: number;
  @Input() method: boolean;
  @Output() onClose = new EventEmitter();
  public newRequest = new ScoringApply();
  public checkToValid = false;
  public requestErrors: any = {};
  public uuid: string;
  public new_method: boolean = false;

  private destroyed$ = new Subject();

  constructor(
    private requestService: RequestService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.newRequest.desired_period = this.month;
    this.newRequest.desired_amount = this.amount;
    this.newRequest.repayment_method =
      String(this.method) == "true" ? "ANNUITY" : "EQUAL_INSTALMENTS";
    this.new_method = String(this.method) == "true";
  }

  updateFunc() {
    this.new_method = !this.new_method;
    this.newRequest.repayment_method = !this.new_method
      ? "EQUAL_INSTALMENTS"
      : "ANNUITY";
  }

  public sendConfirmOTP(request: ScoringApply) {
    this.checkToValid = true;

    if (!this.checkRequest(request)) {
      return this.snackBar.open("Заполните все поля!", null, {
        duration: TimeSnackbarShow,
        verticalPosition: "top",
      });
    }

    this.requestService
      .scoringApply(request)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res: any) => {
          $("#sendOtpConfirmModal").modal("show");
          $("#requestModal").modal("hide");
          this.uuid = res.uuid;
          this.newRequest = new ScoringApply();
        },
        (err: any) => {
          this.requestErrors = err.error;
          let errMsg = "";
          if (err.error && err.error.errors) {
            for (let i = 0; i < err.error.errors.length; i++) {
              errMsg += err.error.errors[i].message + "\n";
            }
          } else {
            for (let key in err.error) {
              errMsg += err.error[key] + "\n";
            }
          }

          this.snackBar.open(errMsg || "Ошибка при отправке!", null, {
            duration: TimeSnackbarShow,
            verticalPosition: "top",
          });

          $("#sendOtpConfirmModal").modal("hide");
          $("#requestModal").modal("show");
        }
      );
  }

  public checkRequest(request: ScoringApply): Boolean {
    if (
      !request.desired_amount ||
      !request.iin ||
      !request.mobile_phone ||
      request.mobile_phone.length < 18 ||
      !request.desired_period ||
      this.requestErrors.mobile_phone | this.requestErrors.iin ||
      !request.agree_personal_data ||
      !request.agree_contact_details ||
      request.desired_amount < 200000 ||
      request.desired_amount > 7000000 ||
      !request.city
    ) {
      return false;
    }

    return true;
  }

  onSelect(city: number) {
    this.newRequest.city = city;
  }

  public checkDesiredAmountToMaxMin(min: number, max: number) {
    if (this.newRequest.desired_amount < min) {
      this.snackBar.open("Минимальная сумма " + min + " тенге", null, {
        duration: TimeSnackbarShow,
        verticalPosition: "top",
      });
      this.newRequest.desired_amount = min;
    }

    if (this.newRequest.desired_amount > max) {
      this.snackBar.open("Максимальная сумма " + max + " тенге", null, {
        duration: TimeSnackbarShow,
        verticalPosition: "top",
      });
      this.newRequest.desired_amount = max;
    }
  }

  public checkDesiredPeriodToMaxMin(min: number, max: number) {
    if (this.newRequest.desired_period < min) {
      this.snackBar.open("Минимальный срок " + min + " мес.", null, {
        duration: TimeSnackbarShow,
        verticalPosition: "top",
      });
      this.newRequest.desired_period = min;
    }

    if (this.newRequest.desired_period > max) {
      this.snackBar.open("Максимальный срок " + max + " мес.", null, {
        duration: TimeSnackbarShow,
        verticalPosition: "top",
      });
      this.newRequest.desired_period = max;
    }
  }

  public checkInputVal(mobile_phone: any) {
    if (this.requestErrors && this.requestErrors.mobile_phone)
      this.requestErrors.mobile_phone = null;

    if (mobile_phone.value[4] == 8 || mobile_phone.value[0] == 8) {
      mobile_phone.value = "+7 (";
      this.snackBar.open("Пишите в формате +7 (700) 000-00-00", null, {
        duration: TimeSnackbarShow,
        verticalPosition: "top",
      });
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
