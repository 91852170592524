import { Component, OnInit, OnDestroy } from '@angular/core';
import { DocumentService } from 'src/app/services/document/document.service';
import { UnsubscribeService } from 'src/app/services/unsubscribe/unsubscribe.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  providers: [
    DocumentService
  ]
})
export class DocumentsComponent implements OnInit, OnDestroy {
  public documents: Array<Document>;
  private subscribes= [];

  constructor(
    private documentService: DocumentService,
    private unsubscribeService: UnsubscribeService
  ) { }

  public docs2020 = [
    { document: "https://mfo-customers.qntm.kz/media/manager_documents/%D0%9E%D1%82%D1%87%D0%B5%D1%82_%D0%BE_%D1%84%D0%B8%D0%BD._%D0%BF%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B8_pmgdNm0.pdf", title: "Отчет о фин. положении", date: "27.08.2021"},
    { document: "https://mfo-customers.qntm.kz/media/manager_documents/%D0%9E%D1%82%D1%87%D0%B5%D1%82_%D0%BE_%D1%81%D0%BE%D0%B2%D0%BE%D0%BA%D1%83%D0%BF%D0%BD%D0%BE%D0%BC_%D0%B4%D0%BE%D1%85%D0%BE%D0%B4%D0%B5_GnGGBdf.pdf", title: "Отчет о совокупном доходе", date: "27.08.2021"},
    { document: "https://mfo-customers.qntm.kz/media/manager_documents/%D0%9E%D1%82%D1%87%D0%B5%D1%82_%D0%BE_%D0%B4%D0%B2%D0%B8%D0%B6%D0%B5%D0%BD%D0%B8%D0%B8_%D0%B4%D0%B5%D0%BD._%D1%81%D1%80-%D0%B2_fvXxgQw.pdf", title: "Отчет о движении ден. ср-в", date: "27.08.2021"},
    { document: "https://mfo-customers.qntm.kz/media/manager_documents/%D0%9E%D1%82%D1%87%D0%B5%D1%82_%D0%BE%D0%B1_%D0%B8%D0%B7%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D1%8F%D1%85_%D0%B2_%D0%BA%D0%B0%D0%BF%D0%B8%D1%82%D0%B0%D0%BB%D0%B5_1mShoRU.pdf", title: "Отчет об изменениях в капитале", date: "27.08.2021"},
    { document: "https://mfo-customers.qntm.kz/media/manager_documents/%D0%9F%D1%80%D0%B8%D0%BC%D0%B5%D1%87%D0%B0%D0%BD%D0%B8%D1%8F_JXADCdG.pdf", title: "Примечания", date: "27.08.2021"},
  ]

  ngOnInit() {
    this.getDocuments();
  }

  private getDocuments() {
    this.subscribes.push(
      this.documentService.getDocuments().subscribe(
        (res: Array<Document>) => {
          this.documents = res;
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }

  ngOnDestroy() {
    this.unsubscribeService.unsubscribings(this.subscribes);
  }

}
