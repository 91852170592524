// core
import { Injectable } from '@angular/core';

// models
import { User } from '../../models/user';

// services
import { LocalStorageService } from 'angular-2-local-storage';

@Injectable({
  providedIn: 'root',
})
export class MyLocalStorageService {

  constructor(
    private localStorageService: LocalStorageService
  ) { }

  get(name: string): any {
    return this.localStorageService.get(name);
  }

  set(name: string, value: any): any {
    if (this.localStorageService.get(name)) {
      this.localStorageService.set(name, value);
    } else {
      this.localStorageService.add(name, value);
    }
  }

  getUser(): User {
    const user: User = this.localStorageService.get('user');
    return user;
  }

  setUser(user: User) {
    this.localStorageService.set('user', user);
  }

  getToken(): string {
    return this.localStorageService.get('token');
  }

  setToken(token: string) {
    this.localStorageService.set('token', token);
  }

  getIdleTime(): Date {
    return this.localStorageService.get('idleStart');
  }

  setIdleTime(idleStartTime: Date) {
    this.localStorageService.set('idleStart', idleStartTime);
  }

  clearStorage() {
    // this.setLastChatOpened(null);
    // this.setToken(null);
    // this.setUser(null);
    this.localStorageService.clearAll();
  }
}
