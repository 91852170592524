// core
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

// services
import { UnsubscribeService } from '../../services/unsubscribe/unsubscribe.service';
import { MyLocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-navbar-header',
  templateUrl: './navbar-header.component.html',
  styleUrls: ['./navbar-header.component.scss']
})
export class NavbarHeaderComponent implements OnInit, OnDestroy {
  private subscribes = new Array<any>();
  public token: string;

  constructor(
    private router: Router,
    private unsubscribeService: UnsubscribeService,
    private localStorage: MyLocalStorageService,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.token = this.localStorage.getToken();
  }

  public anchor(url: string, id: string) {
    if (this.router.url === url) {
      this.scrollToElement(id);
    } else {
      this.router.navigate(['/']).then(()=>{
        this.scrollToElement(id);
      })
    }
  }

  public scrollToElement(id: string) {
    const element: HTMLElement = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 100,
        behavior: "smooth"
      });
    }
  }

  public logout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.unsubscribeService.unsubscribings(this.subscribes);
  }

}
