export class ScoringApply {
  iin: string;
  mobile_phone = "+7 (";
  desired_amount: number;
  desired_period: number;
  repayment_method: string;
  transaction_id: string;
  admitad_uid: string;
  utm_source: string;
  utm: {};
  city: number;

  agree_personal_data: boolean;
  agree_contact_details: boolean;
}
