// core
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

// services
import { MyLocalStorageService } from "../services/local-storage/local-storage.service";
import { UnsubscribeService } from "../services/unsubscribe/unsubscribe.service";

// pipes
import { NumSeparatorPipe } from "../pipes";

// compoments
import { NavbarHeaderComponent } from "../components/navbar-header/navbar-header.component";
import { FooterComponent } from "../components/footer/footer.component";
import { ModalApplicationComponent } from "../components/modal-application/modal-application.component";
import { ModalCardComponent } from "../components/modal-card/modal-card.component";
import { ModalPaymentComponent } from "../components/modal-payment/modal-payment.component";
import { ModalPaymentsComponent } from "../components/modal-payments/modal-payments.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { IMaskModule } from "angular-imask";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { ModalWooppayComponent } from "../components/modal-wooppay/modal-wooppay.component";
import { LoaderComponent } from "../components/loader/loader.component";
import { ModalSigningContractComponent } from "../components/modal-signing-contract/modal-signing-contract.component";
import { ModalOTPComponent } from "../components/modal-otp/modal-otp.component";
import { ModalOTPConfirmComponent } from "../components/modal-otp-confirm/modal-otp-confirm.component";
import { ModalSignComponent } from "../components/modal-sign/modal-sign.component";
import { ModalVariantComponent } from "../components/modal-variant/modal-variant.component";
import { ModalDefaultComponent } from "../components/modal-default/modal-default.component";
import { ModalRejectionVariantComponent } from "../components/modal-rejection-variant/modal-rejection-variant.component";

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    IMaskModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    RouterModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    NavbarHeaderComponent,
    FooterComponent,
    NumSeparatorPipe,
    ModalApplicationComponent,
    ModalCardComponent,
    ModalPaymentComponent,
    ModalPaymentsComponent,
    ModalWooppayComponent,
    ModalSigningContractComponent,
    ModalOTPComponent,
    ModalOTPConfirmComponent,
    ModalSignComponent,
    ModalVariantComponent,
    ModalDefaultComponent,
    ModalRejectionVariantComponent,
    MatSnackBarModule,
    IMaskModule,
    MatProgressSpinnerModule,
    LoaderComponent,
  ],
  declarations: [
    NavbarHeaderComponent,
    FooterComponent,
    NumSeparatorPipe,
    ModalApplicationComponent,
    ModalCardComponent,
    ModalPaymentComponent,
    ModalPaymentsComponent,
    ModalWooppayComponent,
    ModalSigningContractComponent,
    ModalOTPComponent,
    ModalOTPConfirmComponent,
    ModalSignComponent,
    ModalVariantComponent,
    ModalDefaultComponent,
    ModalRejectionVariantComponent,
    LoaderComponent,
  ],
  entryComponents: [],
  providers: [MyLocalStorageService, UnsubscribeService],
})
export class SharedModule {}
