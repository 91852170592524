import { Component, OnInit, OnDestroy } from '@angular/core';

declare let $: any;

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  constructor() { }

  public text: string = ''

  ngOnInit() {
    window['showLoader'] = (text: string) => {
      this.text = text
      $('#loaderStaticBackdrop').modal('show');
      console.log("Loader shown")
    }
    window['hideLoader'] = () => {
      $('#loaderStaticBackdrop').modal('hide');
    }
  }

  ngOnDestroy() {
  }

}
