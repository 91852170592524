import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MyLocalStorageService } from "../local-storage/local-storage.service";
import { environment } from "src/environments/environment";
import { TUserInfo, ChangePassword } from "src/app/models/user";
import { from, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient) {}

  public getUserLoanRequestHistory(fromDate: string, toDate: string) {
    return this.http.get(
      `${environment.apiUrl}/api/user/loan/request/history/?from_date=${fromDate}&to_date=${toDate}`
    );
  }

  public getUserInfo(category = "individual"): Observable<TUserInfo> {
    return this.http.get<TUserInfo>(
      `${environment.apiUrl}/api/user/account/info/?product_category=${category}`
    );
  }

  public getPaymentHistory(): Observable<TUserInfo> {
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/loan/payment/history/?date_from=&date_to=`
    );
  }

  public getRepaymentHistory(
    contract,
    contractDate,
    fromDate,
    toDate,
    category
  ) {
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/loan/repayment/history/?contract_number=${contract}&contract_date=${contractDate}&from_date=${fromDate}&to_date=${toDate}&category=${category}`
    );
  }

  public registerOrResetCard() {
    // return this.http.
  }

  public changePassword(model: ChangePassword): Observable<TUserInfo> {
    let myHeaders = new HttpHeaders();
    myHeaders.append("Content-Type", "multipart/form-data");
    let options = { headers: myHeaders };

    return this.http.post<any>(
      `${environment.apiUrl}/api/user/auth/password/change/`,
      model,
      options
    );
  }

  public getActiveRequest(id: number): Observable<TUserInfo> {
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/${id}/active-request/`
    );
  }

  public getPrintForms(credit_id: string, form_name: string): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/api/content/${credit_id}/print-form/${form_name}.pdf`,
      { responseType: "blob" }
    );
  }

  public uploadFile(
    id: number,
    cardFront: any,
    cardBack: any,
    photo: any
  ): Observable<boolean> {
    const formData: FormData = new FormData();
    formData.append("id_card_front", cardFront, cardFront.name);
    formData.append("id_card_back", cardBack, cardBack.name);
    formData.append("photo", photo, photo.name);

    let myHeaders = new HttpHeaders();
    myHeaders.append("Content-Type", "multipart/form-data");
    let options = { headers: myHeaders };

    return this.http.post<any>(
      `${environment.apiUrl}/api/content/active-request/${id}/kyc-upload/`,
      formData,
      options
    );
  }

  public sendOTP(id: number): Observable<boolean> {
    return this.http.post<any>(
      `${environment.apiUrl}/api/content/active-request/${id}/send-otp/`,
      null
    );
  }

  public singOTP(id: number, otp_code: number): Observable<boolean> {
    return this.http.post<any>(
      `${environment.apiUrl}/api/content/active-request/${id}/sign-otp/`,
      { otp_code: otp_code }
    );
  }

  public cancelRequest(id: number): Observable<boolean> {
    return this.http.post<any>(
      `${environment.apiUrl}/api/content/active-request/${id}/cancel/`,
      null
    );
  }

  public signCourier(id: number): Observable<boolean> {
    return this.http.post<any>(
      `${environment.apiUrl}/api/content/active-request/${id}/sign-by-courier/`,
      null
    );
  }

  public getCardList(contracNumber: string) {
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/card/list/?contract_number=${contracNumber}`
    );
  }

  public deleteCard(id: string) {
    return this.http.delete(
      `${environment.apiUrl}/api/user/card/delete/${id}/`
    );
  }

  public updateCard(card: any) {
    return this.http.put(
      `${environment.apiUrl}/api/user/card/update/${card.id}/`,
      card
    );
  }

  public pay(card: any) {
    return this.http.post(`${environment.apiUrl}/api/user/card/pay/`, card);
  }

  public addCard(data) {
    return this.http.post(`${environment.apiUrl}/api/user/card/create/`, data);
  }

  public getCreditsContract(): Observable<TUserInfo> {
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/loan/credits/contract/`
    );
  }
  public patchCreditsContract(repayment_method: string) {
    return this.http.patch(
      `${environment.apiUrl}/api/user/loan/credits/contract/`,
      {
        repayment_method,
      }
    );
  }
  public getValidateToSign(): Observable<TUserInfo> {
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/loan/credits/validate-to-sign/`
    );
  }
  public postValidateToSign(otp: number): Observable<TUserInfo> {
    return this.http.post<any>(
      `${environment.apiUrl}/api/user/loan/credits/validate-to-sign/`,
      {
        otp,
      }
    );
  }
  public getContracts(): Observable<TUserInfo> {
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/loan/credits/get-contracts/`
    );
  }
  public getSignedPrintForms(url: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}${url}`, {
      responseType: "blob",
    });
  }
}
